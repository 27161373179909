
// Import Tiny Slider library
import { tns } from 'tiny-slider/src/tiny-slider';

export function slider() {
    document.addEventListener('DOMContentLoaded', function () {
        // Function to extract value for a specific breakpoint
        function getValueForBreakpoint(element, attribute, breakpoint) {
            const value = element.getAttribute(`data-${breakpoint}-${attribute}`);
            return value !== null ? value : null;
        }

        // Parse the items and gutter values
        function parseValue(value) {
            if (value === "true") return true;
            if (value === "false") return false;
            return !isNaN(value) ? parseFloat(value) : null;
        }

        // Initialize the sliders
        document.querySelectorAll(".slider-container .slider-items").forEach(sliderElement => {
            // Get data attribute values
            const defaultItems = parseValue(sliderElement.getAttribute("data-items")) || 1;
            const defaultGutter = parseValue(sliderElement.getAttribute("data-gutter")) || 0;
            const defaultNav = parseValue(sliderElement.getAttribute("data-nav"));
            const defaultControls = parseValue(sliderElement.getAttribute("data-controls"));
            const slideby = sliderElement.getAttribute("data-slideby");

            // Define breakpoints and corresponding data attributes
            const breakpoints = {
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
                '2xl': 1440
            };

            // Function to get responsive options for the slider
            function getResponsiveOptions(element) {
                const responsiveOptions = {};
                let previousOptions = {
                    items: defaultItems,
                    gutter: defaultGutter,
                    nav: defaultNav,
                    controls: defaultControls
                };

                Object.keys(breakpoints).forEach(breakpoint => {
                    const breakpointWidth = breakpoints[breakpoint];
                    const itemsForBreakpoint = getValueForBreakpoint(element, 'items', breakpoint);
                    const gutterForBreakpoint = getValueForBreakpoint(element, 'gutter', breakpoint);
                    const navForBreakpoint = getValueForBreakpoint(element, 'nav', breakpoint);
                    const controlsForBreakpoint = getValueForBreakpoint(element, 'controls', breakpoint);

                    const currentOptions = {
                        items: parseValue(itemsForBreakpoint) || previousOptions.items,
                        gutter: parseValue(gutterForBreakpoint) || previousOptions.gutter,
                        nav: parseValue(navForBreakpoint) || previousOptions.nav,
                        controls: parseValue(controlsForBreakpoint) || previousOptions.controls
                    };

                    responsiveOptions[breakpointWidth] = currentOptions;
                    previousOptions = currentOptions;
                });

                return responsiveOptions;
            }

            // Get the container for custom indicators
            // const customIndicatorsContainer = sliderElement.closest('.slider-container').querySelector('.slider-indicators');

            // Initialize TinySlider
            const tnsoptions ={
                container: sliderElement,
                items: defaultItems,
                nav: defaultNav,
                controls: defaultControls,
                controlsPosition: "bottom",
                navPosition: "bottom",
                autoplay: true,
                autoplayTimeout: 4200,
                autoplayButtonOutput: false,
                gutter: defaultGutter,
                preventScrollOnTouch: 'auto',
                autoplayDirection:'forward',
                startIndex:0,
                lazyload: true,
                loop: true,
                edgePadding: 0,
                autoplayHoverPause: false,
                controlsContainer: sliderElement.closest('.slider-container').querySelector('.custom-controllers'),
                nextButton: sliderElement.closest('.slider-container').querySelector('.next'),
                mouseDrag: false,
                useLocalStorage: true,
                autoHeight: false,
                responsive: getResponsiveOptions(sliderElement),
                onInit: function(){
                    sliderElement.classList.remove("invisible");
                }
            }
            if(slideby=='page'){
                tnsoptions.slideBy="page";
            }
            tns(
                tnsoptions
            );
        });
    });
}slider();