import { validateEmail } from "./validateEmail";
import { serializeForm } from "./serializeForm";
import Notify from 'simple-notify';

export function sendMail(){
    var sendMailBtn = document.querySelectorAll(".sendMail");
sendMailBtn.forEach(function(sendMailBtn) {
  sendMailBtn.addEventListener("click", function() {
    var self= this;
    var btnName = this.innerText;
    const form = self.closest("form");
    var phoneInputField=form.querySelector(".number");
    var phone_number = window.intlTelInputGlobals.getInstance(phoneInputField).getNumber();
    const formData = serializeForm(form);
    // phone number validation
    var numberError = window.intlTelInputGlobals.getInstance(phoneInputField).isValidNumber();


    var msg ="Request a Call Back";
    if(form.querySelector(".message").value){
      msg = form.querySelector(".message").value;
    }

    if (form.querySelector(".name").value == "") {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Please enter your name',
        autoclose: true,
      });
      form.querySelector(".name").focus();
    }else if (form.querySelector(".name").length > 30) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Name is too long',
        autoclose: true,
      });
      form.querySelector(".name").focus();
  } else if (form.querySelector(".email").value == "") {
        new Notify ({
          status: 'error',
          title: 'Error',
          text: 'Please enter your email address',
          autoclose: true,
        });
        form.querySelector(".email").focus();
    } else if (!validateEmail(form.querySelector(".email").value)) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Please enter valid email address',
        autoclose: true,
      });
      form.querySelector(".email").focus();
    }else if (form.querySelector(".number").value == "") {
        new Notify ({
          status: 'error',
          title: 'Error',
          text: 'Please enter your phone number',
          autoclose: true,
        });
        form.querySelector(".number").focus();
    }else if (!numberError) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Invalid phone number length for the selected country',
        autoclose: true,
      });
      form.querySelector(".number").focus();
    }
    else if (msg.length > 500) {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Message is too long',
        autoclose: true,
      });
      form.querySelector(".message").focus();
    } else if (msg == "") {
      new Notify ({
        status: 'error',
        title: 'Error',
        text: 'Please write your message',
        autoclose: true,
      });
      form.querySelector(".message").focus();
    } else {
        self.setAttribute('disabled', '');
        self.innerText="Sending...";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "backend/mail.php");
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        // xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var response = xhr.responseText;
                    // var response = JSON.parse(xhr.responseText);
                    if(response == 1) {
                      new Notify ({
                        status: 'success',
                        title: 'Success',
                        text: 'Thank You, Our team will get back to you soon...',
                        autoclose: true,
                      });

                      // Get the current URL
                      var currentUrl = new URL(window.location.href);

                      // Create a new URLSearchParams object based on the current URL's query parameters
                      var searchParams = currentUrl.searchParams;

                      // Add a new parameter to the URL
                      searchParams.set('form_submit', 'form_success');

                      // Update the query string of the current URL
                      currentUrl.search = searchParams.toString();

                      // Replace the current URL with the updated URL
                      window.history.replaceState({}, '', currentUrl.href);

                      // Get the form element
                      var form = document.querySelectorAll("form");
                      if (form) {
                        for (var i = 0; i < form.length; i++) {
                          form[i].reset();
                        }
                      }

                    } else {
                        new Notify ({
                          status: 'error',
                          title: 'Error',
                          text: 'Something Went wrong! Please try again later...',
                          autoclose: true,
                        });
                    }
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    'event': 'cf',
                    'response':{
                      'result':'thank_you',
                      'success_msg':'Thank you'
                    }
                    });
                } else {
                  new Notify ({
                    status: 'error',
                    title: 'Error',
                    text: 'Something Went wrong! Please try again later...',
                    autoclose: true,
                  });
                }
            }
            self.removeAttribute('disabled');
            self.innerText=btnName;
        };
        var currentPage = window.location.origin + window.location.pathname;
        xhr.send(formData+"&phone_number="+encodeURIComponent(phone_number)+"&page="+currentPage);
    }
  });
});
}
sendMail();