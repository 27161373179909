import axios from "axios";

let dataPromise = null;

export function trackOrder(trackingId) {
    if (!dataPromise) {
        dataPromise = new Promise(function(resolve, reject) {
            const opt ={
                method: 'GET',
                url: 'https://crm.leadsgulf.com/main-admin/api-get-attestation-details',
                responseType: 'json',
                params: {
                    attestation_id: trackingId
                }
            }
            axios(opt)
            .then(function(response) {
                resolve(response.data);
            })
            .catch(function(error) {
                reject(error);
            })
            .finally(function() {
                dataPromise = null; // Reset the promise, whether it was successful or not.
            });
        });
    }
    return dataPromise;
}

if(document.querySelector(".trackOrderBtn")){
    document.querySelector(".trackOrderBtn").addEventListener("click", function(){
        const val = document.querySelector(".trackOrderInput").value;
        trackOrder(val)
        .then(function(data) {
    
            var status_array = [
                {'collect': '0%'},
                {'under_process':"25%"},
                {'processing_in_uae':"50%"},
                {'delivered':"100%"}
            ];
    
    
            if(data.success == true){
                document.querySelector(".documentStatusContainer").classList.remove("hidden");
                document.querySelector(".trackOrderMessage").innerText=data.message;
    
                const givenKey =  data.status.replace(/ /g,"_").toLowerCase();
    
                let perfectRange = null;
                let foundIndex = -1;
    
                status_array.forEach((item, index) => {
                    if (item.hasOwnProperty(givenKey)) {
                      perfectRange = item[givenKey];
                      foundIndex = index+1; 
                    }
                });
    
                if (perfectRange !== null) {
                    document.querySelector(".percentageRange").style="width:"+perfectRange;
                    // document.querySelector(".progressBarContainer").querySelector(".")
                } else {
                    console.error(`Key '${givenKey}' not found in the array`);
                }
    
                var listItems = document.querySelectorAll('.progressBarContainer .progress');
                
                for (var i = 0; i < listItems.length; i++) {
                    listItems[i].style.color = '';
                }
    
                for (var i = 0; i < foundIndex; i++) {
                    listItems[i].style.color = "#b7a003";
                    // listItems[i].childNodes.style.border="1px solid #b7a003";  
                }
            }
        })
        .catch(function(error) {
            console.error("Error fetching data:", error);
        });
    });
}