export function validatePhoneNumber(){
    const phone_number = document.querySelectorAll(".number");
    if(phone_number){
        phone_number.forEach(function (numberInput) {
            numberInput.addEventListener("keydown", function (event) {
                // Allow backspace and delete keys
                if (event.key === "Backspace" || event.key === "Delete" || event.key === "+") {
                    return;
                }
        
                // Allow only numbers
                if (isNaN(event.key)) {
                    event.preventDefault();
                }
            });
        });
    }
}
validatePhoneNumber();